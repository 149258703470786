var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-block-list-visual-shortcuts-custom"},[(_vm.title)?_c('BaseH2',{staticClass:"text-primary-button mb-sm",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.linkItems.length > 0)?_c('div',{staticClass:"\n\t\t\tc-block-list-visual-shortcuts-custom__overview\n\t\t\t<1024:hidden\n\t\t\tflex\n\t\t\tw-full\n\t\t"},[_c('div',{staticClass:"flex flex-col space-y-2 w-4col",attrs:{"role":"tablist"}},_vm._l((_vm.computedLinkItems),function(item,index){return _c('button',{key:("tab-" + index),class:[
					'rounded-tl-lg rounded-bl-lg',
					'font-semibold text-body text-left',
					'duration-300 ease-smooth-out',
					'group flex-1 p-sm',

					{
						'bg-white': _vm.activeTab === item.key,
						'bg-primary-medium': _vm.activeTab !== item.key,
					} ],attrs:{"id":("c-block-list-visual-shortcuts-custom__tab-" + (item.key)),"role":"tab","aria-selected":_vm.activeTab === item.key ? 'true' : 'false',"aria-controls":("c-block-list-visual-shortcuts-custom__content-panel-" + (item.key))},on:{"click":function($event){return _vm.setActiveTab(item.key)}}},[_c('div',{class:[
						'flex items-center space-x-xs',
						'duration-300 ease-smooth-out group-hover:pl-8' ]},[(item.content && item.content.icon)?_c('div',{staticClass:"w-sm flex-shrink-0"},[_c('PageIcon',{attrs:{"name":item.content.icon.icon}})],1):_vm._e(),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(item.content.title)}})])])}),0),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\tbg-white\n\t\t\t\trounded-tr-lg rounded-br-lg\n\t\t\t\toverflow-hidden\n\t\t\t\tw-full\n\t\t\t"},_vm._l((_vm.computedLinkItems),function(item,i){return _c('NuxtLinkExt',{directives:[{name:"show",rawName:"v-show",value:(item.key === _vm.activeTab),expression:"item.key === activeTab"}],key:("tablist-" + i),ref:("content-" + (item.key)),refInFor:true,staticClass:"group relative",attrs:{"id":("c-block-list-visual-shortcuts-custom__content-panel-" + (item.key)),"aria-hidden":item.key !== _vm.activeTab,"aria-labelledby":("c-block-list-visual-shortcuts-custom__tab-" + (item.key)),"role":"tabpanel","to":item.content &&
					item.content.link &&
					item.content.link.length &&
					item.content.link[0].url,"target":item.content &&
					item.content.link &&
					item.content.link.length &&
					item.content.link[0].target,"tag":item.content &&
					item.content.link &&
					item.content.link.length
						? 'a'
						: 'div'}},[(item.content.image)?_c('UmbracoImageExt',{class:[
						'w-full h-full',
						'bg-white relative',
						'transform duration-500 ease-smooth-out group-hover:scale-105' ],attrs:{"alt":item.content.image.altText,"widths":[
						320,
						480,
						720,
						960,
						1200,
						1440,
						1920,
						1200 * 2,
						1440 * 2,
						1920 * 2 ],"source-url":item.content.image.cropUrl || item.content.image.url,"source-width":item.content.image.width,"source-height":item.content.image.height,"aspect-ratio":864 / 446,"image-class-names":"object-cover"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"\n\t\t\t\t\t\tbg-white\n\t\t\t\t\t\trounded-sm\n\t\t\t\t\t\tp-sm\n\t\t\t\t\t\toverflow-hidden\n\t\t\t\t\t\tw-3col\n\t\t\t\t\t\tabsolute\n\t\t\t\t\t\tbottom-md\n\t\t\t\t\t\tright-md\n\t\t\t\t\t"},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.content.teaser)+"\n\t\t\t\t\t"),_c('BaseButton',{staticClass:"w-fit mt-xs",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(
									item.content.link[0].target === '_blank'
								)?_c('SvgExternalArrowIcon',{staticClass:"w-14 h-12 text-white"}):_c('SvgArrowIcon',{staticClass:"w-14 h-12 text-white"})]},proxy:true}],null,true)})],1)],1)}),1)]):_vm._e(),_vm._v(" "),(_vm.computedLinkItems.length > 0)?_c('div',{staticClass:">=1024:hidden"},[_c('AccordionLayout',{ref:"mobileAccordion",attrs:{"max-one-open":true,"min-one-open":true,"elements":_vm.computedLinkItems,"has-caret":false},on:{"change":function (ref) {
						var index = ref.index;
						var state = ref.state;

						return state && _vm.setActiveTab(_vm.computedLinkItems[index].key);
}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
					var item = ref.item;
return [_c('div',{staticClass:"flex items-center space-x-sm py-8"},[(item.content && item.content.icon)?_c('div',{staticClass:"flex-shrink-0 w-32"},[_c('PageIcon',{attrs:{"name":item.content.icon.icon}})],1):_vm._e(),_vm._v(" "),_c('BaseH2',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(item.content.title)}})],1)]}},{key:"panel",fn:function(ref){
					var item = ref.item;
return [(item.content.link[0] && item.content.link[0].url)?_c('NuxtLinkExt',{key:("mobile-link-" + (item.key)),staticClass:"block",attrs:{"to":item.content.link[0].url,"target":item.content.link[0].target}},[_c('span',{domProps:{"textContent":_vm._s(item.content.teaser)}}),_vm._v(" "),_c('BaseButton',{staticClass:"w-fit mt-md",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(
									item.content.link[0].target === '_blank'
								)?_c('SvgExternalArrowIcon',{staticClass:"w-14 h-12 text-white"}):_c('SvgArrowIcon',{staticClass:"w-14 h-12 text-white"})]},proxy:true}],null,true)})],1):_vm._e()]}}],null,false,3221510143)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }