<template>
	<div class="c-block-list-visual-shortcuts-custom">
		<BaseH2 v-if="title" class="text-primary-button mb-sm" v-text="title" />
		<!-- Desktop -->
		<div
			v-if="linkItems.length > 0"
			class="
				c-block-list-visual-shortcuts-custom__overview
				<1024:hidden
				flex
				w-full
			"
		>
			<!-- Tabs -->
			<div class="flex flex-col space-y-2 w-4col" role="tablist">
				<button
					v-for="(item, index) in computedLinkItems"
					:id="`c-block-list-visual-shortcuts-custom__tab-${item.key}`"
					:key="`tab-${index}`"
					role="tab"
					:aria-selected="activeTab === item.key ? 'true' : 'false'"
					:aria-controls="`c-block-list-visual-shortcuts-custom__content-panel-${item.key}`"
					:class="[
						'rounded-tl-lg rounded-bl-lg',
						'font-semibold text-body text-left',
						'duration-300 ease-smooth-out',
						'group flex-1 p-sm',

						{
							'bg-white': activeTab === item.key,
							'bg-primary-medium': activeTab !== item.key,
						},
					]"
					@click="setActiveTab(item.key)"
				>
					<div
						:class="[
							'flex items-center space-x-xs',
							'duration-300 ease-smooth-out group-hover:pl-8',
						]"
					>
						<div
							v-if="item.content && item.content.icon"
							class="w-sm flex-shrink-0"
						>
							<PageIcon :name="item.content.icon.icon" />
						</div>
						<span v-text="item.content.title"></span>
					</div>
				</button>
			</div>

			<!-- Content -->
			<div
				class="
					bg-white
					rounded-tr-lg rounded-br-lg
					overflow-hidden
					w-full
				"
			>
				<NuxtLinkExt
					v-for="(item, i) in computedLinkItems"
					v-show="item.key === activeTab"
					:id="`c-block-list-visual-shortcuts-custom__content-panel-${item.key}`"
					:key="`tablist-${i}`"
					:ref="`content-${item.key}`"
					:aria-hidden="item.key !== activeTab"
					:aria-labelledby="`c-block-list-visual-shortcuts-custom__tab-${item.key}`"
					role="tabpanel"
					class="group relative"
					:to="
						item.content &&
						item.content.link &&
						item.content.link.length &&
						item.content.link[0].url
					"
					:target="
						item.content &&
						item.content.link &&
						item.content.link.length &&
						item.content.link[0].target
					"
					:tag="
						item.content &&
						item.content.link &&
						item.content.link.length
							? 'a'
							: 'div'
					"
				>
					<UmbracoImageExt
						v-if="item.content.image"
						:alt="item.content.image.altText"
						:widths="[
							320,
							480,
							720,
							960,
							1200,
							1440,
							1920,
							1200 * 2,
							1440 * 2,
							1920 * 2,
						]"
						:source-url="
							item.content.image.cropUrl || item.content.image.url
						"
						:source-width="item.content.image.width"
						:source-height="item.content.image.height"
						:aspect-ratio="864 / 446"
						image-class-names="object-cover"
						:class="[
							'w-full h-full',
							'bg-white relative',
							'transform duration-500 ease-smooth-out group-hover:scale-105',
						]"
					/>
					<div
						class="
							bg-white
							rounded-sm
							p-sm
							overflow-hidden
							w-3col
							absolute
							bottom-md
							right-md
						"
					>
						{{ item.content.teaser }}
						<BaseButton tag="div" class="w-fit mt-xs">
							<template #icon>
								<SvgExternalArrowIcon
									v-if="
										item.content.link[0].target === '_blank'
									"
									class="w-14 h-12 text-white"
								/>
								<SvgArrowIcon
									v-else
									class="w-14 h-12 text-white"
								/>
							</template>
						</BaseButton>
					</div>
				</NuxtLinkExt>
			</div>
		</div>
		<!-- Mobile -->
		<div v-if="computedLinkItems.length > 0" class=">=1024:hidden">
			<AccordionLayout
				ref="mobileAccordion"
				:max-one-open="true"
				:min-one-open="true"
				:elements="computedLinkItems"
				:has-caret="false"
				@change="
					({ index, state }) =>
						state && setActiveTab(computedLinkItems[index].key)
				"
			>
				<template #header="{ item }">
					<div class="flex items-center space-x-sm py-8">
						<div
							v-if="item.content && item.content.icon"
							class="flex-shrink-0 w-32"
						>
							<PageIcon :name="item.content.icon.icon" />
						</div>
						<BaseH2 tag="h2" v-text="item.content.title" />
					</div>
				</template>

				<template #panel="{ item }">
					<NuxtLinkExt
						v-if="item.content.link[0] && item.content.link[0].url"
						:key="`mobile-link-${item.key}`"
						:to="item.content.link[0].url"
						:target="item.content.link[0].target"
						class="block"
					>
						<span v-text="item.content.teaser"></span>
						<BaseButton tag="div" class="w-fit mt-md">
							<template #icon>
								<SvgExternalArrowIcon
									v-if="
										item.content.link[0].target === '_blank'
									"
									class="w-14 h-12 text-white"
								/>
								<SvgArrowIcon
									v-else
									class="w-14 h-12 text-white"
								/>
							</template>
						</BaseButton>
					</NuxtLinkExt>
				</template>
			</AccordionLayout>
		</div>
	</div>
</template>

<script>
import AccordionLayout from '~/components/shared/AccordionLayout';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import PageIcon from '~/components/shared/PageIcon';
import SvgArrowIcon from '~/assets/svgs/arrow-icon.svg?inline';
import SvgExternalArrowIcon from '~/assets/svgs/external-arrow-icon.svg?inline';

export default {
	name: 'BlockListVisualShortcutsCustom',

	components: {
		AccordionLayout,
		UmbracoImageExt,
		PageIcon,
		SvgArrowIcon,
		SvgExternalArrowIcon,
	},

	inheritAttrs: false,

	props: {
		linkItems: Array,
		title: String,
	},

	data() {
		return {
			activeTab: null,
		};
	},

	computed: {
		computedLinkItems() {
			return this.linkItems.filter(
				(item) => item.content.image && item.content.link?.length
			);
		},
	},

	mounted() {
		this.activeTab = this.linkItems[0].key;

		if (this.$refs?.mobileAccordion) {
			let index = 0;
			this.linkItems.forEach((e, n) => {
				if (e.id === this.activeTab) {
					index = n;
				}
			});

			this.$refs.mobileAccordion.openPanel(index);
		}
	},

	methods: {
		setActiveTab(id) {
			this.activeTab = id;
		},
	},
};
</script>
<style lang="postcss">
.c-block-list-visual-shortcuts-custom .c-accordion-layout__header,
.c-block-list-visual-shortcuts-custom .c-accordion-layout__panel {
	@apply bg-primary-medium !important;
}
.c-block-list-visual-shortcuts-custom .c-accordion-layout__header--active,
.c-block-list-visual-shortcuts-custom .c-accordion-layout__panel--active {
	background-color: #fff !important;
}
.c-block-list-visual-shortcuts-custom .c-accordion-layout__panel {
	@apply pt-0 pb-24 !important;
}
</style>
